<template>
  <div class="cardlist">
    <div class="lii">
      <div class="card">
        <img
          width="100%"
          height="100%"
          :src="productPic || $store.state.Imgpic"
          alt
        />
        <div v-if="rows.loans" class="icon-box">贷</div>
        <div v-if="rows.helpFlag == '0'" class="ishelp">
          <img src="@/assets/images/cart/ishelp.png" alt="" />
        </div>
      </div>
      <div class="goodsDetail">
        <div class="h1">
          {{ rows.productName }}
          <span v-show="rows.code">(商品编码{{ rows.code }})</span>
          <span v-show="rows.isGift === 1" style="color: #FF7D00">(赠品)</span>
        </div>
        <div style="margin-top: 5px" class="info">
          厂家： {{ rows.manufacturer }}
        </div>
        <div class="info">件装量： {{ rows.packSpec }}{{ rows.unit }}</div>
        <!-- <div  v-if="!rows.validPeriodTime" class="info">效期： {{ rows.validPeriod }}个月</div>
        <div  v-if="rows.validPeriodTime" class="info">效期至： {{ rows.validPeriodTime | dateFormat }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    rows: {
      handler(value) {
        //压缩图片
        if (value.productPic && value.productPic.lastIndexOf("show") !== -1) {
          this.productPic =
            value.productPic
              .split(",")?.[0]
              .replace(
                "show",
                "showcompress?compressType=size&value=264x264"
              ) ||
            value.productPic.replace(
              "show",
              "showcompress?compressType=size&value=264x264"
            );
        } else {
          this.productPic =
            value.productPic.split(",")?.[0] || value.productPic;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      productPic: "",
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
.cardlist {
  text-align: left;
  padding: 10px;
}
.lii {
  display: flex;
  .card {
    position: relative;
    text-align: center;
    border: 1px solid @hui;
    width: 100px;
    height: 100px;
    box-sizing: border-box;

    .ishelp {
      position: absolute;
      width: 100%;
      bottom: 0;
      img{
        width:100%;
        vertical-align: bottom;
      }
    }
    .icon-box{
      position: absolute;
      top:0;
      right:0;
      width:15px;
      height:20px;
      font-size: 12px;
      color:#ffffff;
      text-align: center;
      line-height: 20px;
      background-image: url(../../../assets/images/goods-icon.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .goodsDetail {
    flex: 1;
    margin-left: 16px;
    .h1 {
      font-size: 14px;
      font-family: 'medium';
      color: #131313;
      font-size: 16px;
      font-weight: 500;
    }
    .info {
      font-size: 12px;
      color: #adafad;
    }
  }
}
</style>
