<template>
  <div class="body">
    <div class="banxin">
      <div v-if="!Aftersales" class="OrderDetail_top">
        <div class="tt df">
          <div class="suxian"></div>
          订单详情
        </div>
        <div v-if="status == 170" class="steps df jfc">
          <div
            v-for="(item, index) in endsteps"
            :key="index"
            class="tou df aic"
          >
            <div class="tep" style="text-align: center">
              <div style="font-size: 14px; color: #333; margin-bottom: 17px">
                {{ item.tep }}
              </div>
              <div style="margin-bottom: 17px" class="status">
                <img
                  style="icon20"
                  :src="index + 1 <= active ? item.status.T : item.status.F"
                  alt
                />
              </div>
              <div class="time">
                <div style="color: #707070;
                    font-size: 12px;
                    line-height: 1.5;
                    width: 80px;
                  "
                  class="day"
                >
                  {{ item.createTime }}
                </div>
              </div>
            </div>
            <div
              :style="
                index + 1 >= active + 1
                  ? 'background-image:linear-gradient(to right, #CDCDCD 0%, #CDCDCD 50%, transparent 50%);'
                  : ''
              "
              class="xuxian"
            ></div>
          </div>
        </div>
        <div v-if="status != 170" class="steps df jfc">
          <div v-for="(item, index) in steps" :key="index" class="tou df aic">
            <div class="tep" style="text-align: center">
              <div style="font-size: 14px; color: #333; margin-bottom: 17px">
                {{ item.tep }}
              </div>
              <div style="margin-bottom: 17px" class="status">
                <img
                  style="icon20"
                  :src="index + 1 <= active ? item.status.T : item.status.F"
                  alt
                />
              </div>
              <div class="time">
                <div
                  style="
                    color: #707070;
                    line-height: 1.5;
                    width: 80px;
                    font-size: 12px;
                  "
                  class="day"
                >
                  {{ item.createTime }}
                </div>
              </div>
            </div>
            <div
              :style="
                index + 1 >= active + 1
                  ? 'background-image:linear-gradient(to right, #CDCDCD 0%, #CDCDCD 50%, transparent 50%);'
                  : ''
              "
              class="xuxian"
            ></div>
          </div>
        </div>
        <div class="bolang"></div>
      </div>

      <div class="OrderDetail_mid">
        <div class="list">
          <div class="h1 aic df">
            <div style="margin-right: 9px" class="suxian"></div>
            订单信息
          </div>
          <div class="li df aic fs14">
            <div class="fhui">订单状态：</div>
            <div>{{ statusName }}</div>
            <div
              v-if="oderList.order.endtime && status == 100"
              class="fred df aic fs14"
            >
              <img
                style="margin-left: 8px; margin-right: 4px"
                class="icon14"
                src="@/assets/images/person/warning.png"
                alt
              />
              剩余付款时间：{{ oderList.order.endtime }}
            </div>
            <div
              v-if="oderList.order.closeReason && status == 170"
              class="fred df aic fs14"
            >
              &nbsp; 理由：{{ oderList.order.closeReason }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">订单编号：</div>
            <div>{{ oderList.order.orderSn }}</div>
          </div>
          <div class="li fs14">
            <div class="fhui">支付编号：</div>
            <div>{{ oderList.order.paySn }}</div>
          </div>
          <div class="li fs14">
            <div class="fhui">订单来源：</div>
            <div class="nwp">
              {{ oderList.order.orderSource }}
              &nbsp;
            </div>
            <div
              v-if="
                oderList.order.orderSource &&
                  oderList.order.orderSource.substr(0, 1) !== '自'
              "
              style="margin-left: 10px; width: 40px"
              @click="copywxtap(oderList.order.orderSource)"
              ref="copytext"
              class="cup gr"
            >
              复制
            </div>
          </div>
          <!-- <div v-if="oderList.order.payTypeName" class="li fs14">
            <div class="fhui">支付方式：</div>
            <div>{{ oderList.order.payTypeName }}</div>
          </div> -->
          <div class="li fs14">
            <div class="fhui">下单时间：</div>
            <div>{{ oderList.order.createTime }}</div>
          </div>
        </div>
        <div v-if="!Aftersales" class="list">
          <div class="h1 df aic">
            <div style="margin-right: 9px" class="suxian"></div>
            收货信息
          </div>
          <div class="li fs14">
            <div class="fhui">客户：</div>
            <div>{{ oderList.order.customerName }}</div>
          </div>
          <div class="li fs14">
            <div class="fhui">收货方式：</div>
            <div>
              {{ oderList.order.receiveType == 1 ? "送货上门" : "到仓自提" }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">收货人：</div>
            <div>
              {{ oderList.order.takeName || oderList.order.receiveName }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">联系方式：</div>
            <div>
              {{ oderList.order.takePhone || oderList.order.receivePhone }}
            </div>
          </div>
          <div class="li fs14">
            <div class="fhui">收货地址：</div>
            <div class="fr">
              {{ oderList.order.receiveAddress }}
            </div>
          </div>
        </div>
        <div class="list remark-list">
          <div class="h1 df aic">
            <div style="margin-right: 9px" class="suxian"></div>
            备注
          </div>
          <div class="remark-box">
            <div class="remark-info">{{oderList.order.remark || '暂无'}}</div>
            <div v-if="oderList.order.status < 140" class="remark-img" @click="openRemark">
              <img src="@/assets/images/order/green-edit.png" alt="">
              <span>修改</span>
            </div>
          </div>
        </div>
      </div>

      <div class="OrderDetail_foot">
        <div class="list">
          <el-table
            :header-cell-style="{ background: '#fff' }"
            :data="oderList.orderItems"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="500px"
          >
            <el-table-column label="商品" align="center" min-width="180">
              <template slot-scope="scope">
                <Detail-Card :rows="scope.row"> </Detail-Card>
              </template>
            </el-table-column>
            <el-table-column
              style="color: #333333"
              prop="spec"
              align="center"
              label="规格"
              width="auto"
            ></el-table-column>
            <el-table-column
              style="color: #333333"
              prop="salesPrice"
              align="center"
              label="单价(元)"
              width="auto"
            ></el-table-column>
            <el-table-column
              style="color: #333333"
              prop="productQuantity"
              align="center"
              label="数量"
              width="auto"
            ></el-table-column>
            <el-table-column
              style="color: #333333"
              prop="ofsNum"
              align="center"
              label="出库数量"
              width="auto"
            ></el-table-column>
            <el-table-column
              style="color: #333333"
              prop="ofsAmount"
              align="center"
              label="出库金额"
              width="auto"
            ></el-table-column>
            <el-table-column
              style="color: #333333"
              width="100"
              align="center"
              label="总计(元)"
            >
              <template slot-scope="scope">
                <div style="color: #333333">
                  {{
                    (scope.row.salesPrice * scope.row.productQuantity).toFixed(
                      2
                    )
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="prodact">
          <div class="li">
            <div class="fs14">下单数量：</div>
            <div class="fs14 tar">{{ oderList.order.totalPackNum }}</div>
          </div>
          <div class="li">
            <div class="fs14">下单金额：</div>
            <div class="fs14 tar">{{ formatMoney(oderList.order.totalAmount,'￥') }}</div>
          </div>
          <div v-if="status > 110" class="li">
            <div class="fs14">出库数量：</div>
            <div style="color:#26C487" class="fs14 tar">
              {{ oderList.order.ofsQuantityTotal }}
            </div>
          </div>
          <div v-if="status > 110" class="li">
            <div class="fs14">出库金额：</div>
            <div class="fs14 tar">
              {{ formatMoney(oderList.order.ofsAmountTotal,'￥') }}
            </div>
          </div>
          <div v-if="status > 110" class="li">
            <div class="fs14">慧金豆抵扣：</div>
            <div class="fs14 tar">
              {{ formatMoney(oderList.order.huiCoinDiscount,'￥') }}
            </div>
          </div>
          <div v-if="status > 110" class="li">
            <div class="fs14">药采贷支付：</div>
            <div class="fs14 tar">
              {{ formatMoney(oderList.order.loanAmount,'￥') }}
            </div>
          </div>
          <div v-if="status > 110 && oderList.order.quotaAmount > 0" class="li">
            <div class="fs14">账期支付：</div>
            <div class="fs14 tar">
              {{  formatMoney(oderList.order.quotaAmount,'￥') }}
            </div>
          </div>
          <div v-if="status > 110 && oderList.order.cashAmount > 0" class="li">
            <div class="fs14">现金支付：</div>
            <div class="fs14 tar">
              {{ formatMoney(oderList.order.cashAmount,'￥') }}
            </div>
          </div>
          <div class="li fred">
            <div class="fs14">应付总金额：</div>
            <div class="fs18 blod tar">
              {{
                oderList.order.status > 100
                  ? formatMoney(oderList.order.payAmount,'￥')
                  : formatMoney(oderList.order.totalAmount,'￥')
              }}
            </div>
          </div>
          <div>
            <el-button v-if="status == 100" @click="goPay" type="success" style="margin-left: auto;">立即支付</el-button>
            <el-button v-if="status == 100 || status == 110" @click="orderCancel(oderList.order.id)">{{ status == 100 ? '取消订单' : '取消支付' }}</el-button>
          </div>

          <!-- 确认收货-->
          <div v-if="status == 140" class="li flex-end btn-box">
            <el-button @click="goPay(6)" type="success">确认收货</el-button>
          </div>
          <!-- 申请售后 -->
          <div  class="li flex-end btn-box">
           <!--  <el-button
              v-if="refundIcon"
              @click="goPay(3)"
              type="success"
              >申请售后</el-button
            > -->
            <!-- v-if="status == 160 && oderList.order.applyRefundStatus !== 1" -->
            <!-- applyRefundStatus == 2 已退款状态 -->
            <el-button
              type="success"
              v-if="
                status == 160 &&
                  oderList.order.applyRefundStatus == 1 &&
                  oderList.order.applyRefundStatus == 2
              "
              @click="goPay(4)"
              >售后详情</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 备注弹窗 -->
    <el-dialog :visible.sync="remarkVisible" width="480px">
      <div slot="title">
        <div class="remark-title">{{remark ? '修改备注' : '添加备注'}}</div>
      </div>
      <el-input v-model="remark" type="textarea" :rows="3" placeholder="请输入内容" maxlength="50" show-word-limit></el-input>
      <span slot="footer">
        <el-button @click="toggleRemark(0)">取 消</el-button>
        <el-button type="primary" @click="toggleRemark(1)">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DetailCard from "@/components/custom-base/custom-product-detail-card";
import emptyGoodsImg from "@/assets/images/empty-product.jpg";
import {
  productReceived,
  orderDetail,
  orderCancel,
  orderCancelAPI,
  applyRefundDetail,
  editRemarkAPI
} from "@/request/Orders/order";
import {formatMoney} from '@/utils/tool'
export default {
  components: {
    DetailCard,
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, olVal) {
        if (newVal.params.id) {
          this.orderId = newVal.params.id;
          localStorage.setItem("orderDetailId", newVal.params.id);
        } else {
          this.orderId = localStorage.getItem("orderDetailId");
        }
      },
    },
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    // localStorage.removeItem("orderDetailId");
    clearInterval(this.timer);
  },
  mounted() {
    this.timer = setInterval(() => {
      let _this = this;
      _this.daojishi();
    }, 1000);
  },
  data() {
    return {
      formatMoney,
      name: "",
      //寄回填写物流公司
      deliveryCompany: "",
      //寄回状态
      completeStatus: 0,
      emptyGoodsImg,
      orderId: "",
      statusName: "全部",
      status: 0, //订单状态：-1全部 审批中-50 待付款-100 支付中-110 支付中-120 部分发货-130 待收货-140 部分收货-150 已完成-160 已关闭-170 已撤销-180
      oderList: {
        order: { endtime: "", payEndTime: null },
      },
      steps: [
        {
          createTime: "",
          tep: "审批中",
          status: {
            T: require("@/assets/images/details/s1-t.png"),
            F: require("@/assets/images/details/s1-f.png"),
          },
        },
        {
          createTime: "",
          tep: "待付款",
          status: {
            T: require("@/assets/images/details/s2-t.png"),
            F: require("@/assets/images/details/s2-f.png"),
          },
        },
        {
          createTime: "",
          tep: "支付中",
          status: {
            T: require("@/assets/images/details/s3-t.png"),
            F: require("@/assets/images/details/s3-f.png"),
          },
        },
        {
          createTime: "",
          tep: "待收货",
          status: {
            T: require("@/assets/images/details/s4-t.png"),
            F: require("@/assets/images/details/s4-f.png"),
          },
        },
        {
          createTime: "",
          tep: "完成",
          status: {
            T: require("@/assets/images/details/s5-t.png"),
            F: require("@/assets/images/details/s5-f.png"),
          },
        },
      ],
      endsteps: [
        {
          createTime: "",
          tep: "生成订单",
          status: {
            T: require("@/assets/images/details/s1-t.png"),
            F: require("@/assets/images/details/s1-f.png"),
          },
        },
        {
          createTime: "",
          tep: "已关闭",
          status: {
            T: require("@/assets/images/details/s5-t.png"),
            F: require("@/assets/images/details/s5-f.png"),
          },
        },
      ],
      active: 1,
      //售前
      Aftersales: null,
      refundIcon:false,
      // 备注
      remarkVisible: false,
      remark: ''
    };
  },
  methods: {
    //取消订单
    orderCancel(id) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if(this.status == 100){
          orderCancel({ orderId: id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("取消成功");
              this.$router.push("/user/myOrder");
            }
          });
        }
        if(this.status == 110){
          orderCancelAPI({ orderId: id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("取消成功");
              this.$router.push("/user/myOrder");
            }
          });
        }
      });
    },
    getList() {
      orderDetail({ orderId: this.orderId }).then((res) => {
        if (res.code === 200 && res.data != null) {
          this.oderList = res.data;

          this.status = res.data.order.status;
          this.getRefundIcon();
          //已关闭
          this.endsteps[0].createTime = this.oderList.order.createTime;
          this.endsteps[1].createTime = this.oderList.order.closedTime;
          //完整流程
          this.steps[0].createTime = this.oderList.order.createTime;
          this.steps[1].createTime = this.oderList.order.waitPayTime;
          this.steps[2].createTime = this.oderList.order.paymentTime;
          this.steps[3].createTime = this.oderList.order.waitReceivedTime;
          this.steps[4].createTime = this.oderList.order.completedTime;
          if (res.data.order.status) {
            this.daojishi();
          }
          //订单状态：-1全部 待付款-100 支付中-110 支付中-120 部分发货-130 待收货-140 部分收货-150 已完成-160 已关闭-170

          switch (res.data.order.status) {
            case 50:
              this.statusName = "审批中";
              this.active = 1;
              break;
            case 100:
              this.statusName = "待付款";
              this.active = 2;
              break;
            case 110:
              this.statusName = "支付中";
              this.active = 3;
              break;
            case 120:
              this.statusName = "支付中";
              this.active = 4;
              break;
            case 130:
              this.statusName = "部分发货";
              this.active = 4;
              break;
            case 140:
              this.statusName = "待收货";
              this.active = 4;
              break;
            case 150:
              this.statusName = "部分收货";
              this.active = 5;
              break;
            case 160:
              this.statusName = "已完成";
              this.active = 5;
              break;
            case 170:
              this.statusName = "已关闭";
              this.active = 2;
              break;
            case 180:
              this.statusName = "已撤销";
              this.active = 1;
              break;

            default:
              break;
          }
        }
      });
    },
    showtime(endtime) {
      var nowtime = new Date(), //获取当前时间
        endtime = new Date(endtime); //定义结束时间
      var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      if (leftd > -1 && lefth > -1) {
        if (leftd > -1 && lefth > -1) {
          if (leftd >= 1) {
            return leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
          } else if (leftd <= 0 && lefth >= 1) {
            return lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
          } else {
            return leftm + "分" + lefts + "秒"; //返回倒计时的字符串
          }
        }
      }
    },
    daojishi() {
      if (!this.oderList.order.payEndTime) {
        clearInterval(this.timer);
      } else {
        let endtime = "";
        endtime = this.showtime(this.oderList.order.payEndTime);
        this.oderList.order.endtime = endtime;
        this.$forceUpdate();
      }
    },
    // 复制
    copywxtap(txt) {
      let all = [];
      all.push(txt);
      this.copyContent = all.join(); //也可以直接写上等于你想要复制的内容
      var input = document.createElement("input"); // 直接构建input
      input.value = this.copyContent; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    //   立即支付
    goPay(index) {
      if (this.status == 100 || this.status == 110) {
        // let orderList = {
        //   orderIds: [this.oderList.order.id],
        //   realAmount: this.oderList.order.payAmount,
        //   orderNum: 1,
        //   huiCoinDiscount: this.oderList.order.huiCoinDiscount,
        // };
        this.$router.push({
          path: "/newPay",
          query: { checkPay: [this.oderList.order.id] },
        });
      } else if (index == 3) {
        this.$router.push({
          path: "/User/MyOrder/aftersele",
          query: { id: this.oderList.order.id },
        });
      } else if (index == 4) {
        applyRefundDetail({ applyId: this.oderList.order.applyId }).then(
          (res) => {
            if (res.code === 200) {
              let data = { ...res.data, id: this.oderList.order.id };
              this.$router.push({
                name: `AfterseleDetail`,
                params: data,
              });
            }
          }
        );
      } else if (index == 6) {
        this.$confirm('是否确认收货？确认收货后一天内可对该订单进行售后处理', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          productReceived({ orderId: this.oderList.order.id }).then((res) => {
            if (res.code === 200) {
              this.$message.success("确认成功,即将返回上一页");
              setTimeout(() => {
                this.$router.push("/user/myOrder");
              }, 1500);
            }
          });
        }).catch(() => {});
      }
    },

    // 计算是否出现售后按钮 只能在订单完成后24小时内发起售后
    getRefundIcon() {
      if (this.oderList.order.completedTime) {
        let differ = Date.now() - new Date(this.oderList.order.completedTime).getTime();
        let h = Math.floor(differ / 1000 / 60 / 60);
        if (h < 24 && this.status === 160) this.refundIcon = true;
      }
    },

    // 备注弹窗
    openRemark() {
      this.remark = this.oderList.order.remark;
      this.remarkVisible = true;
    },
    toggleRemark(type) {
      if(type === 1) {
        let params = {
          orderId: this.orderId,
          remark: this.remark
        }
        editRemarkAPI(params).then(res => {
          if(res.code === 200) {
            this.$message.success('备注修改成功!')
            this.getList()
          }
        })
      }

      this.remarkVisible = false;
      this.remark = '';
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-table__cell {
    color: #333333;
  }
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 20px;
  }
  .el-input__inner {
    width: 360px;
    height: 32px;
    line-height: 32px;
  }
  .el-step__title {
    font-size: 14px;
    color: #3333;
    position: absolute;
    top: -70%;
    left: 40%;
  }
  .el-step__title.is-process {
    font-size: 14px;
    color: #3333;
    font-weight: 400;
  }
}
.fr {
  line-height: 1.2;
  text-align: left;
  flex: 1;
}
.p32 {
  padding: 0px !important;
}
.time {
  height: 36px;
}
.xuxian {
  padding-bottom: 25px;
  width: 169px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    @green 0%,
    @green 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}
.xuxianF {
  width: 169px;
  height: 15px;
  background-image: linear-gradient(
    to right,
    #cdcdcd 0%,
    #cdcdcd 50%,
    transparent 50%
  );
  background-size: 23px 2px;
  background-repeat: repeat-x;
}
.tou:last-child {
  .xuxian {
    display: none;
  }
}
.OrderDetail-head {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 30px 30px 30px;
  margin-bottom: 20px;
}
.OrderDetail_top {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  border-top: 2px solid @green;
  .bolang {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 16px;
    background: #fff;
    background: url("../../../assets/images/details/reduis.png");
  }
}
.OrderDetail_mid {
  justify-content: space-around;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  .list {
    width: 590px;
    border-right: 1px solid #f5f5f5;
    padding: 30px;
  }
  .remark-list {
    width: 200px;
    border: none;
  }
  .li {
    text-align: right;
    color: #333333;
    display: flex;
    margin-bottom: 16px;
    .fhui {
      min-width: 70px;
      max-width: 70px;
      color: #9e9fab;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
  .h1 {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 26px;
  }
}
.body {
  background-color: @body;
}
.prodact {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 30px;
  text-align: right;
  color: #707070;
  background-color: #fff;
  .li {
    width: 240px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .fs14 {
      width: 160px;
      text-align: right;
    }
    .tar {
      flex: 1;
    }
  }
}
.OrderDetail_foot {
  margin-top: 30px;
}
.suxian {
  height: 16px;
}
.tt {
  font-size: 16px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
  margin-bottom: 29px;
}

.remark-box {
  
  .remark-info {
    word-wrap: break-word;
    max-height: 200px;
    overflow-y: auto;
  }
  .remark-img {
    color: #26C487;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    img {
      margin-right: 8px;
    }
  }
}

::v-deep .el-dialog__header {
  background: #F7F8FA;
  padding-bottom: 20px;
}
.remark-title {
  font-size: 20px;
  font-family: 'medium';
  font-weight: 500;
  color: #202D40;
  height: 20px;
  padding-left: 10px;
  border-left: 5px solid #26C487;
}
</style>
